import React from "react";
import Image from "gatsby-image";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { ArrowSmallEx, ArrowDownload } from "components/Arrow";
import { SEO } from "components/SEO";

import pdfFile from "assets/pdf/BM_EN_PressRelease_20190528_AnnouncementOfBlueMedicalAG_RZ.pdf";
import pdfFile2 from "assets/pdf/BM_EN_Flyer-Packaging_2020-06_A4_RGB.pdf";

// import News from "components/News/News";

const NewsTempContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1.5rem;
	margin: 7.5rem 0 0;
	padding: 0;
	list-style: none;

	@media (min-width: 64.01px) and (max-width: 87.5rem) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 40.01px) and (max-width: 64rem) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 40rem) {
		grid-template-columns: 1fr;
	}

	.news__card-item-new {
		border: 0.12rem solid var(--gray24);
		height: 30rem;
		display: flex;
		flex-direction: column;
		padding: 1.88rem 2.125rem 1.38rem;

		@media (max-width: 64rem) {
			height: auto;
		}
	}

	.card-item__content {
		font-size: 1.5rem;
		position: relative;
		margin-bottom: 4rem;

		&:after {
			content: "";
			position: absolute;
			bottom: -2rem;
			left: 0;
			height: 0.19rem;
			width: 1.81rem;
			background-color: var(--blue);
		}
	}

	.link-wrapper,
	.dl-wrapper {
		margin-top: auto;
	}

	.dl-wrapper {
		/* display: inline-flex;
		align-items: center; */

		svg {
			transform: none;
			margin-right: 0.5rem;
		}
	}
`;

const NewsPage = ({ data }) => {
	return (
		<Layout pageName="news">
			<SEO
				title="Blue Medical News"
				description="Information about developments at Blue Medical and in the world of medical packaging."
			/>
			<Container sectionName="news-hero">
				<Image
					fluid={data.newsHeader.childImageSharp.fluid}
					alt="The flag of Switzerland."
				/>
			</Container>
			<Intro className="news-intro">
				<h1 className="mono">News, Events and Press Release</h1>
				<p>
					We keep you up-to-date with our new developments and the
					world of medical packaging.
				</p>
			</Intro>
			<Container sectionName="news-container">
				{/* <div className="news__filter">
					<ul>
						<li className="active">All</li>
						<li>News</li>
						<li>Events</li>
						<li>Press Release</li>
					</ul>
				</div> */}
				<NewsTempContainer>
					<div className="news__card-item-new">
						<div className="card-item__date mono">2020 June</div>
						<div className="card-item__content copy-large">
							Our packaging services for the medical technology
							and healthcare industry
						</div>
						<div className="news_links dl-wrapper">
							<ArrowDownload />
							<a
								href={pdfFile2}
								target="_blank"
								rel="noopener noreferrer"
							>
								Overview
							</a>
						</div>
					</div>
					<div className="news__card-item-new">
						<div className="card-item__date mono">
							2019 September
						</div>
						<div className="card-item__content copy-large">
							Keynote speech at our symposium on 11 September 2019
							on the focus topic „Intelligent Packaging Solutions“
						</div>
						<div className="news_links link-wrapper">
							<ArrowSmallEx />
							<a
								href="https://www.linkedin.com/pulse/kombinationsprodukte-anforderungen-herausforderungen-carrieri/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Keynote
							</a>
						</div>
					</div>
					<div className="news__card-item-new">
						<div className="card-item__date mono">2019 May</div>
						<div className="card-item__content copy-large">
							IL-MedTec AG announces to change its name to Blue
							Medical AG, effective 24 May 2019
						</div>
						<div className="news_links dl-wrapper">
							<ArrowDownload />
							<a
								href={pdfFile}
								target="_blank"
								rel="noopener noreferrer"
							>
								Press Release
							</a>
						</div>
					</div>
					<div className="news__card-item-new">
						<div className="card-item__date mono">
							2019 February
						</div>
						<div className="card-item__content copy-large">
							We have been successfully recertified according to
							the quality standards required by ISO 13485:2016.
						</div>
						<div className="news_links link-wrapper">
							<ArrowSmallEx />
							<Link to="/quality/">Quality</Link>
						</div>
					</div>
				</NewsTempContainer>

				{/* <News /> */}
			</Container>
		</Layout>
	);
};

export default NewsPage;

export const pageQuery = graphql`
	query {
		newsHeader: file(
			relativePath: { eq: "news/BM_News-Swissness_2928x480.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 1464) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
